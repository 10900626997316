<template>
  <div class="job-share">
    <div class="bg">
      <div class="head">
        <van-row class="name-wrapper">
          <van-col span="18" class="name">
            <span class="van-multi-ellipsis--l2">{{ info.name }}</span>
          </van-col>
          <van-col span="6" class="salary">{{
            salaryText(info.salary)
          }}</van-col>
        </van-row>

        <div class="name-wrapper">
          {{ jobInfo }}
        </div>

        <div class="tag-wrapper">
          <span class="tag" v-for="(item, i) in tags" :key="item + i">
            {{ item }}
          </span>
        </div>
      </div>

      <div class="tab">
        <span class="tab1" @click="jump" :class="{ active: tab == 1 }"
          >详情</span
        >
        <span class="tab2" @click="jump" :class="{ active: tab == 2 }"
          >公司</span
        >
        <span class="tab3" @click="jump" :class="{ active: tab == 3 }"
          >推荐</span
        >
      </div>
    </div>

    <div class="main">
      <div class="detail">
        <div class="title">
          <van-image :src="require('@/assets/images/detail.png')"></van-image>
          <span>岗位详情</span>
        </div>

        <div class="gray-bg">
          <div class="flex-middle">
            <van-image
              :src="require('@/assets/images/location.png')"
              width="13"
              height="16"
            ></van-image>
            <span>工作地点：</span>
          </div>
          <div>{{ address || '暂无' }}</div>
        </div>

        <div class="gray-bg">
          <div class="flex-middle">
            <van-image
              :src="require('@/assets/images/miaoshu.png')"
              width="15"
              height="15"
            ></van-image>
            <span>职位描述：</span>
          </div>
          <div v-html="content || '暂无'"></div>
        </div>

        <div class="gray-bg">
          <div class="flex-middle">
            <van-image
              :src="require('@/assets/images/yaoqiu.png')"
              width="17"
              height="15"
            ></van-image>
            <span>职位要求：</span>
          </div>
          <div v-html="requirement || '暂无'"></div>
        </div>

        <div class="tip">
          <div class="tip-title">
            <van-icon name="info-o" />
            <span>易校招提示</span>
          </div>
          <div class="tip-content">
            如遇到虚假职位、收取费用、所要身份证信息等行为请<span
              @click="toDownload"
              >立即举报</span
            >或立即拨打<a href="tel:4008-628-528">4008-628-528</a>
          </div>
        </div>
      </div>

      <div
        class="company"
        @click="$router.push(`companyShare?id=${info.companyId}`)"
      >
        <div class="card">
          <div class="name-block">
            <YxzAvatar
              class="left border-image"
              :src="info.companyLogo"
              :defaultSrc="require('@/assets/images/fault-ava.png')"
            />
            <div class="right">
              <div class="name-wrapper">
                <span class="van-ellipsis">{{ info.companyName }}</span>

                <van-image
                  v-if="!!info.whetherFamousEnterprises"
                  :src="require('@/assets/images/mingxingqiye.png')"
                ></van-image>
              </div>

              <div class="info-wrapper">
                <span class="van-ellipsis" v-if="info.companyIndustry">{{
                  info.companyIndustry
                }}</span>
                <span class="van-ellipsis" v-if="info.companyNature">{{
                  info.companyNature
                }}</span>
                <span
                  class="van-ellipsis"
                  v-if="companyScale(this.info.companyScale)"
                  >{{ companyScale(this.info.companyScale) }}</span
                >
              </div>
            </div>
          </div>

          <div class="job-count">
            在招职位共{{ info.professionCount || '0' }}个
          </div>

          <div
            class="jobs"
            @click.stop="
              $router.push(`companyShare?id=${info.companyId}&tab=2`)
            "
          >
            <div class="job-list">
              <span
                class="job-block van-ellipsis"
                :key="item.id"
                v-for="item in info.positionList"
              >
                {{ item.name }}
              </span>
            </div>

            <div class="more">查看更多 <van-icon name="arrow" /></div>
          </div>
        </div>
      </div>

      <div class="recommended">
        <div class="title">
          <van-image
            width="17"
            height="17"
            :src="require('@/assets/images/tuijianzhiwei.png')"
          ></van-image>
          <span>推荐职位</span>
        </div>

        <div
          class="item van-hairline--bottom"
          v-for="item in recommended"
          :key="item.id"
          @click="clickRecommended(item.id)"
        >
          <div class="name-wrapper">
            <span class="name van-ellipsis">{{ item.name }}</span>
            <span class="salary">{{ salaryText(item.salary) }}</span>
          </div>

          <div class="tags">
            <van-tag
              class="van-ellipsis"
              color="#F5F5F5"
              text-color="#666"
              size="medium"
              >{{ item.cityName }}</van-tag
            >
            <van-tag
              class="van-ellipsis"
              color="#F5F5F5"
              text-color="#666"
              size="medium"
              >{{ eduText(item.educationalBackground) }}</van-tag
            >
          </div>

          <div class="name-block">
            <yxz-avatar
              class="left border-image"
              :src="item.companyLogo"
              :defaultSrc="require('@/assets/images/fault-ava.png')"
            />

            <div class="right">
              <div class="name-wrapper">
                <span class="van-ellipsis">{{ item.companyName }}</span>
              </div>
              <div class="info-wrapper">
                <span class="van-ellipsis" v-if="item.companyIndustry">{{
                  item.companyIndustry
                }}</span>
                <span class="van-ellipsis" v-if="item.companyNature">{{
                  item.companyNature
                }}</span>
                <span
                  class="van-ellipsis"
                  v-if="companyScale(item.companyScale)"
                  >{{ companyScale(item.companyScale) }}</span
                >
              </div>
            </div>
          </div>

          <van-image
            v-if="item.isReward"
            class="float"
            :src="require('@/assets/images/red-envelope.png')"
          />
        </div>
      </div>
    </div>

    <div class="envelope" v-if="info.isReward">
      <div class="left">
        <van-image
          width="14"
          height="17"
          :src="require('@/assets/images/red-envelope.png')"
        ></van-image>
        <span class="yellow">{{ info.reward.moneyReward }}元赏金</span>
        <span class="white">剩余{{ info.reward.surplusNumber }}人</span>
      </div>

      <div class="right" @click="showRule">
        <span>领赏规则</span>
        <van-icon name="question-o" />
      </div>
    </div>

    <div class="footer van-safe-area-bottom">
      <span @click="toDownload"><van-icon name="star-o" /> 收藏职位</span>
      <van-button color="#F5E929" class="post" @click="gopage"
        >立即投递</van-button
      >
    </div>
  </div>
</template>

<script>
import { Notify, Dialog, Row, Col, Icon, Button, Image, Tag } from 'vant';
import { getJobShare, getSameTypeRecommend } from '@/api/api';
import YxzAvatar from '@/components/Avatar';
import download from '@/composables/download';
import wxShare from '@/composables/wx';
import filters from '@/composables/filters';

export default {
  name: 'JobShare',

  setup() {
    const { downloadHandler } = download();
    const { wxShareHandler } = wxShare();
    const { salaryText, eduText } = filters();

    return {
      downloadHandler,
      wxShareHandler,
      salaryText,
      eduText,
    };
  },

  data() {
    return {
      id: this.$route.query.id,
      name: this.$route.name,
      info: {},
      recommended: [],
      tab: 1,
      scrollTimer: null,
    };
  },

  computed: {
    jobInfo() {
      return [
        this.eduText(this.info.educationalBackground),
        this.natureText,
        this.info.number ? this.info.number + '人' : '',
      ]
        .filter((item) => !!item)
        .join(' | ');
    },

    address() {
      return [this.info.cityName || '', this.info.area || '']
        .filter((item) => !!item)
        .join(' ');
    },

    tags() {
      try {
        return JSON.parse(this.info.tag)
          .concat(
            [
              '',
              '无工作餐有住宿',
              '有工作餐有住宿',
              '有工作餐无住宿',
              '无工作餐无住宿',
            ][this.info.board]
          )
          .filter((item) => !!item);
      } catch (err) {
        return [];
      }
    },

    requirement() {
      if (this.info.requirement) {
        return this.info.requirement
          .replace(/</gi, '&lt;')
          .replace(/\n/gi, '<br/>');
      }

      return '';
    },

    content() {
      if (this.info.content) {
        return this.info.content
          .replace(/</gi, '&lt;')
          .replace(/\n/gi, '<br/>');
      }

      return '';
    },

    natureText() {
      return ['不限', '全职', '实习'][this.info.nature] || '';
    },
  },

  watch: {
    $route: {
      handler(val) {
        const { id } = val.query;

        if (val.name !== this.name) return;

        if (!id) {
          return Notify('链接已损坏，请重新分享打开');
        }

        this.id = id;
        window.scrollTo(0, 0);
        this.getInfo();
        this.getRecommend();
      },

      immediate: true,
    },
  },

  mounted() {
    window.addEventListener('scroll', this.scrollHandler);
  },

  beforeUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);

    if (this.scrollTimer) {
      clearTimeout(this.scrollTimer);
      this.scrollTimer = null;
    }
  },

  methods: {
    scrollHandler() {
      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        const scrollTop = window.screen.height;

        const tab2Top = document
          .querySelector('.company')
          .getBoundingClientRect().y;
        const tab3Top = document
          .querySelector('.recommended')
          .getBoundingClientRect().y;

        if (tab3Top < scrollTop / 2) {
          this.tab = 3;
        } else if (tab2Top < scrollTop / 2) {
          this.tab = 2;
        } else {
          this.tab = 1;
        }
      }, 300);
    },

    showRule() {
      Dialog.alert({
        title: '领赏规则',
        message:
          '在72小时内与企业面试且时间超过5分钟完成后可得全额赏金，如投递简历后企业认为条件不符合岗位要求，不开始面试流程，学生只可获得20%赏金。',
        confirmButtonText: '知道了',
      });
    },

    getInfo() {
      getJobShare({
        params: {
          professionId: this.id,
        },
        loading: true,
      }).then((res) => {
        this.info = res;

        this.wxShareHandler({
          title: res.name,
          url: window.location.href,
          icon: 'https://yxzapp-dev.oss-cn-shanghai.aliyuncs.com/191598693566_.pic_hd.jpg',
          desc: `${res.cityName} | ${res.companyName}`,
        });
      });
    },

    getRecommend() {
      getSameTypeRecommend({
        params: {
          professionId: this.id,
        },
        loading: true,
      }).then((res) => {
        if (Array.isArray(res)) {
          this.recommended = res;
        }
      });
    },

    jump({ target }) {
      let top = 196;

      if (target.className.includes('tab1')) {
        top = document.querySelector('.detail').offsetTop;
        this.tab = 1;
      } else if (target.className.includes('tab2')) {
        top = document.querySelector('.company').offsetTop;
        this.tab = 2;
      } else if (target.className.includes('tab3')) {
        top = document.querySelector('.recommended').offsetTop;
        this.tab = 3;
      }

      top -= 196;

      window.scrollTo({
        top: top,
        behavior: 'smooth',
      });
    },

    toDownload() {
      this.downloadHandler(
        'student',
        JSON.stringify({
          id: this.id,
          type: 'openjobinfo',
        })
      );
    },

    gopage() {
      const { id, liveAnchorLiveId } = this.$route.query;

      if (!liveAnchorLiveId) {
        this.toDownload();
        return;
      }

      this.$router.push({
        name: 'deliverresume',
        query: {
          position: this.info.name,
          company: id,
          liveAnchorLiveId: liveAnchorLiveId,
        },
      });
    },

    clickRecommended(id) {
      if (!id) {
        return;
      }
      const { liveAnchorLiveId } = this.$route.query;
      this.$router.push({
        query: {
          id,
          liveAnchorLiveId,
        },
      });
    },

    companyScale(val) {
      return (
        [
          '',
          '10人以下',
          '10-20人',
          '20-50人',
          '50-200人',
          '200-1000人',
          '1000人以上',
        ][val] || ''
      );
    },
  },

  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [Image.name]: Image,
    [Tag.name]: Tag,
    YxzAvatar,
  },
};
</script>

<style lang="scss" scoped>
.job-share {
  font-size: 14px;
}

.border-image :deep(img) {
  border-radius: 4px;
}

.envelope {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 68px;
  background: rgba(255, 0, 0, 0.6);
  height: 37px;
  line-height: 37px;
  padding: 0 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  font-size: 12px;

  .left {
    display: flex;
    align-items: center;
  }

  .yellow {
    margin-left: 6px;
    color: #faf494;
  }

  .white {
    margin-left: 9px;
    color: #fff;
  }

  .van-icon {
    margin-left: 5px;
  }
}

.bg {
  background: #fff url('~@/assets/images/jobshare-bg.png') no-repeat;
  background-position: 0 0;
  background-size: 100% auto;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 196px;
  z-index: 9;
}

.head {
  height: 140px;
  overflow: auto;

  .name-wrapper {
    margin: 10px 20px;
  }

  .name {
    font-size: 16px;
  }

  .salary {
    text-align: right;
    font-size: 18px;
    color: #ec2929;
  }

  .tag-wrapper {
    display: flex;
    overflow-x: auto;
    padding-bottom: 5px;

    .tag {
      display: inline-block;
      padding: 8px 15px;
      background: #fffbde;
      border-radius: 4px;
      white-space: nowrap;
      margin-left: 10px;
      margin-right: 10px;

      + .tag {
        margin-left: 0;
      }
    }
  }
}

.tab {
  padding: 12px 10px;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  text-align: center;

  .tab1 {
    padding: 5px 26px 7px 20px;
    display: inline-block;
    background: url('~@/assets/images/tab-bg1.png') no-repeat;
    background-size: cover;

    &.active {
      background-image: url('~@/assets/images/tab-bg1-a.png');
    }
  }

  .tab2 {
    margin-left: -10px;
    padding: 5px 27px 7px 25px;
    display: inline-block;
    background: url('~@/assets/images/tab-bg2.png') no-repeat;
    background-size: cover;

    &.active {
      background-image: url('~@/assets/images/tab-bg2-a.png');
    }
  }

  .tab3 {
    margin-left: -10px;
    padding: 5px 20px 7px 23px;
    display: inline-block;
    background: url('~@/assets/images/tab-bg3.png') no-repeat;
    background-size: cover;

    &.active {
      background-image: url('~@/assets/images/tab-bg3-a.png');
    }
  }
}

.main {
  padding: 196px 10px 75px;
  background: #fff;
}

.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding-left: 5px;
}

.detail .title {
  margin: 16px 0 9px;
}

.detail .title .van-image {
  width: 15px;
  height: 19px;
}

.van-image + * {
  margin-left: 5px;
}

.flex-middle {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.gray-bg {
  margin-top: 3px;
  padding: 10px;
  background: #fafaf7;
  color: #666666;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 68px;
  line-height: 68px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #fff;
}

.post {
  float: right;
  width: 215px;
  height: 44px;
  margin-top: 12px;
  margin-right: 15px;
  border-radius: 6px;
  color: #333 !important;
}

.tip {
  margin: 10px;
  border: 1px solid #f63f3f;
  background: #fffbfb;
  border-radius: 8px;
  opacity: 0.6;
  padding: 9px;
  font-size: 10px;
}

.tip-title span {
  margin-left: 5px;
}

.tip-content {
  margin-top: 5px;
  padding-left: 16px;

  span,
  a {
    color: #ef3333;
  }
}

.company {
  margin: 0 -10px;
  padding: 10px;
  background: #fafaf7;
}

.card {
  background: #fff;
  border-radius: 8px;
  padding: 10px;
}

.name-block {
  display: flex;
  height: 45px;

  .left {
    flex: 0 0 45px;
    width: 45px;
  }

  .right {
    margin-left: 5px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;

    .name-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        flex: 1;
        font-size: 18px;
      }

      .van-image {
        margin-left: 10px;
        flex: 0 0 63px;
        width: 63px;
        height: 18px;
      }
    }

    .info-wrapper {
      font-size: 10px;
      color: #666666;
      display: flex;
      overflow: hidden;

      span {
        display: inline-block;
        margin-right: 20px;
        max-width: 40%;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.job-count {
  margin: 10px 0 5px;
  font-size: 10px;
  color: #333;
}

.more {
  flex: 0 0 auto;
  margin-left: 20px;
  color: #999999;
}

.jobs {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.job-list {
  display: flex;
  overflow: hidden;
}

.job-block {
  display: inline-block;
  margin-right: 10px;
  flex: 1;
  padding: 7px 14px;
  background: #f5f5f5;
  border-radius: 4px;
  text-align: center;
  overflow: hidden;
}

.recommended {
  padding: 10px 0;

  .item {
    margin-right: -10px;
    padding: 20px;
    padding-left: 10px;
    position: relative;

    .name-block {
      height: 36px;

      .left {
        flex: 0 0 36px;
        width: 36px;
      }

      .name-wrapper span {
        font-size: 12px;
      }

      .info-wrapper {
        padding-right: 20px;

        span {
          margin-right: 10px;
        }
      }
    }

    .name-wrapper {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      font-size: 18px;

      .salary {
        margin-left: 10px;
        flex: 0 0 auto;
        color: #ec2929;
      }
    }

    .tags {
      padding-bottom: 8px;
      white-space: nowrap;
      margin: 4px 0;
      overflow-x: auto;

      .van-tag {
        margin-right: 5px;
      }
    }

    .float {
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 14px;
    }
  }
}
</style>
